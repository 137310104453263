<template>
  <div class="notice-detail-wrap pt-nav">
    <Header :title="$t('notice.title')" :bgColor="'#000000'" :subTitleBgColor="'#000000'" sub-title="详情" />
    <div class="content-box">
      <div class="title">{{ title }}</div>
      <div class="date">{{ formatLocalTime(createdAt) }}</div>
      <div class="content">
        <div v-html="formatText(detail)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: 'NoticeDetail',
  components: { Header },
  data() {
    return {
      title: '',
      createdAt: '',
      detail: ''
    }
  },
  methods: {
    formatLocalTime(time) {
      return this.$moment(time).format('YYYY-MM-DD HH:mm')
    },
    formatText(str) {
      if (!str) return
      const regex = /(https?:\/\/\S+)/g
      str = str.replace(/\n/g, '<br/>')
      return str.replace(regex, '<a href="$1" target="_blank">$1</a>')
    },
  },
  mounted() {
    this.title = this.$route.query.title
    this.createdAt = this.$route.query.createdAt
    this.detail = this.$route.query.detail
  }
}
</script>

<style scoped lang="scss">
.notice-detail-wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;


  .content-box {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 4px;
  }

  .date {
    font-size: 13px;
    color: #fff;
    margin-bottom: 20px;
  }

  .content {
    color: rgba(255, 255, 255, 0.85);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow-y: auto;
    flex: 1;
    padding-bottom: 20px;
    /deep/ >div {
      a {
        color: #FD2084;
        text-decoration: underline;
      }
    }
  }
}
</style>